/* Footer.css */

.footer {
  background-color: white;
  color: black;
  padding: 20px 0;
  text-align: center;
}

.footer-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.footer-logo img {
  max-width: 150px;
}

.footer-section {
  flex: 1;
  min-width: 200px;
  margin: 10px 0;
}

.footer-section h4 {
  margin-bottom: 10px;
  font-size: 18px;
}

.footer-section ul {
  list-style-type: none;
  padding: 0;
}

.footer-section ul li {
  margin: 5px 0;
}

.footer-section ul li a {
  color: #3eaed1;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-section ul li a:hover {
  color: #21a1f1;
}

.social-links {
  display: flex;
  justify-content: center;
  padding: 0;
}

.social-links a {
  margin: 0 10px;
  color: #3eaed1;
  font-size: 24px;
  transition: color 0.3s ease;
}

.social-links a:hover {
  color: #21a1f1;
}

@media (max-width: 768px) {
  .footer-container {
    flex-direction: column;
    align-items: center;
  }

  .footer-section {
    text-align: center;
  }
}
